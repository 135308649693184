@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&family=Montserrat:wght@400;500;600;700&family=Ubuntu:wght@400;500&display=swap');


:root {
  --font-header: 'Montserrat', sans-serif;
  --font-content: 'IBM Plex sans', sans-serif;
}

.h0 {
    font-family: var(--font-header);
    font-weight: 800;
    font-size: 30px;
    line-height: 40px;
}

.h1 {
    font-family: var(--font-header);
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
}

.h2 {
    font-family: var(--font-header);
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
}

.h21 {
    font-family: var(--font-header);
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
}

.h3 {
    font-family: var(--font-header);
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

.h4 {
    font-family: var(--font-header);
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.h5 {
    font-family: var(--font-header);
    font-weight: 500;
    font-size: 18px !important;
    line-height: 24px !important;
}

.table-header {
    font-family: var(--font-header);
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.buttons {
    font-family: var(--font-content);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.main-text {
    font-family: var(--font-content);
    font-weight: 400;
    font-size: 16px !important;
    line-height: 20px !important;
}

.caption {
    font-family: var(--font-content);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.help-text {
    font-family: var(--font-content);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.small {
    font-family: var(--font-content);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
