.Icon_i88udxd{width:72px;}.Icon_i88udxd img{width:inherit;}
.QrIcon_q8tophi{position:absolute;top:0;right:0;}
.HeaderC_hw945qe{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;position:relative;}
.Title_t100geqw{font-family:var(--font-content);}
.Detail_d1ctknun{margin-top:12px;}

.BlockA_b140diit{padding:28px;background-color:white;border-radius:24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:24px;-webkit-flex:1;-ms-flex:1;flex:1;}
.Header_h1lkgrj9{white-space:pre-line;}
.List_l10ox87w{margin-top:48px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:32px;}

