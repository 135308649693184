
.Date_dfvy2qc.Date_dfvy2qc{display:block;margin-top:12px;opacity:0.6;}

.Divider_d6faxw0{height:1px;margin:32px 0;background-color:white;opacity:0.1;}

.Item_inrbe9c{list-style-type:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:12px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.Info_ihvbsr{padding-left:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:14px;}
.Submit_sk645s2{margin-top:32px;}.Submit_sk645s2 .button_b2aizej{width:100%;border-radius:8px;background-color:#7D6FE1;color:white;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}

.Footer_f1mtmtxu{margin-top:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:8px;}
.Container_crx11oh{background-color:#3B4168;color:white;padding:40px;border-radius:24px;}
