.Title_t1g8prcu{margin-bottom:32px;}
.TimeBlock_t1i1v4x2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:16px;}

.Banner_birhggm{margin-top:32px;}
.TopText_tqiltxu{display:inline-block;margin-top:32px;white-space:pre-line;}
.ImgBlock_i1cj1akd{margin-top:32px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:24px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media screen and (min-width:374px) and (max-width:992px){.ImgBlock_i1cj1akd{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}}.ImgBlock_i1cj1akd img{max-width:50%;}@media screen and (min-width:374px) and (max-width:992px){.ImgBlock_i1cj1akd img{max-width:100%;}}
.QuoteBlock_q1ekpebf{white-space:pre-line;margin:32px 0;padding-left:16px;border-left:4px solid var(--brand-primary);color:var(--text-secondary);}@media (min-width:375px){.QuoteBlock_q1ekpebf{font-size:14px;}}
.BottomText_b1s806fu{white-space:pre-line;}

.Container_c1ju1tbf{max-width:1016px;}
