.root {
  --brand-primary: #0071cE;
  --brand-hover: #40a9ff;
  --text-primary: #3b4168;
  --text-secondary: #6f7c98;
  --text-thirdly: #12152a;
  --text-gray: #909ebb;
  --bg-primary: #f5f6f8;
  --bg-secondary: #fff;
  --el-lines: #e4e7f2;
  --el-dropdown: #e0f1ff;
  --el-disabled: #bdbdbd;
  --el-hover-2: #f5fbff;
  --el-active: #99C6EB;
  --link-visited: #7D6FE1;
  --link-visited-hover: #7467D1;
  --status-green: #73D071;
  --status-green-lite: #73D0710f;
  --status-warning: #F7B055;
  --status-error: #EB5757;
  --block-open: #37d062;
  --block-open-hover: #33C25B;
  --eyes-tag-hover: #006AC2;
  --colors-helsinki: #73AAFF;
  --colors-helsinki-hover: #6CA0F0;
  --checkbox-gray: #DAE4EE;
}
